<template>
  <div class="container">
    <div class="row py-5 auth-row">
      <div class="col-12 col-md-5">
        <img
          class="img-fluid"
          src="@/assets/img/cedaci-img.jpg"
          alt="CEDaCI Main image"
        />
      </div>
      <div class="col-12 col-md-7 text-center">
        <h1 class="title-w2" style="margin-bottom: 3rem">Welcome to Compass</h1>
        <div class="row g-3">
          <div class="col-10 col-md-5">
            <div class="form-item">
              <label class="form-label">First name</label>
              <span class="form-error">{{ formError.firstName }}</span>
              <input
                type="text"
                class="form-control"
                placeholder="Your first name"
                v-model="form.firstName"
                required
              />
            </div>
          </div>
          <div class="col-10 col-md-5">
            <div class="form-item">
              <label class="form-label">Last name</label>
              <span class="form-error">{{ formError.lastName }}</span>

              <input
                type="text"
                class="form-control"
                placeholder="Your last name"
                v-model="form.lastName"
                required
              />
            </div>
          </div>
          <div class="col-10 col-md-5">
            <div class="form-item">
              <label class="form-label">Username</label>
              <span class="form-error">{{ formError.username }}</span>
              <input
                type="text"
                class="form-control"
                placeholder="Your username"
                v-model="form.username"
                required
              />
            </div>
          </div>
          <div class="col-10 col-md-5">
            <div class="form-item">
              <label class="form-label">Organisation</label>
              <span class="form-error">{{ formError.company }}</span>

              <input
                type="text"
                class="form-control"
                placeholder="Your organisation"
                v-model="form.company"
                required
              />
            </div>
          </div>
          <div class="col-10">
            <div class="form-item">
              <label class="form-label">Email</label>
              <span class="form-error">{{ formError.email }}</span>

              <input
                type="email"
                class="form-control"
                placeholder="Your email address"
                v-model="form.email"
                required
              />
            </div>
          </div>

          <div class="col-10">
            <div class="form-item input-group">
              <label class="form-label">Password</label>
              <span class="form-error">{{ formError.password }}</span>

              <input
                :type="showPassword ? 'text' : 'password'"
                class="form-control"
                placeholder="Your password"
                v-model="form.password"
                required
              />
              <span class="input-group-text cursor-pointer"
                ><i
                  :class="
                    showPassword ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'
                  "
                  @click="showPassword = !showPassword"
                ></i
              ></span>
            </div>
          </div>
          <div class="col-10 mb-4">
            <div class="form-item input-group">
              <label class="form-label">Confirm password</label>
              <span class="form-error">{{ formError.passwordConfirm }}</span>
              <input
                :type="showConfirmPassword ? 'text' : 'password'"
                class="form-control"
                placeholder="Please confirm your password"
                v-model="form.passwordConfirm"
                required
              />
              <span class="input-group-text cursor-pointer"
                ><i
                  :class="
                    showConfirmPassword
                      ? 'bi bi-eye-slash-fill'
                      : 'bi bi-eye-fill'
                  "
                  @click="showConfirmPassword = !showConfirmPassword"
                ></i
              ></span>
            </div>
          </div>

          <div class="col-10 mb-4 check-box">
            <div class="form-check">
              <input
                v-model="privacyPolicy"
                class="form-check-input"
                type="checkbox"
                value=""
                id="privacyPolicy"
                required
              />
              <label class="form-check-label" for="privacyPolicy">
                By submitting this form, you confirm that you have read our
                <!-- <span class="link" @click="showModal = true">
                  Privacy Policy</span
                > -->
                <a
                  class="link"
                  href="https://www.lsbu.ac.uk/__data/assets/pdf_file/0004/11686/university-data-protection-policy.pdf"
                  target="_blank"
                  >Privacy Policy</a
                >
                and you agree to those terms.
              </label>
            </div>
          </div>
          <button class="btn w-75 mt-2 mb-2" @click="register">
            Create your account
          </button>
          <p>or</p>
          <router-link :to="{ name: 'Login' }"
            ><button class="btn btn-outlined w-75">Login</button></router-link
          >
        </div>
      </div>
    </div>
  </div>
  <!-- <el-dialog
    v-model="showModal"
    custom-class="privacy-modal"
    title="Privacy Policy"
  >
    <p></p>

    <a
      class="link"
      href="https://www.lsbu.ac.uk/__data/assets/pdf_file/0004/11686/university-data-protection-policy.pdf"
      target="_blank"
      >More information</a
    >
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="showModal = false">Confirm</el-button>
      </span>
    </template>
  </el-dialog> -->
</template>

<script>
import { getFormValidation } from '@/utils/helpers/getValidation';
import Feedback from '@/utils/services/Feedback';

export default {
  name: 'Register',
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        username: '',
        company: '',
        email: '',
        password: '',
        passwordConfirm: '',
      },
      formError: {
        firstName: '',
        lastName: '',
        username: '',
        company: '',
        email: '',
        password: '',
        passwordConfirm: '',
      },
      isValid: false,
      privacyPolicy: false,
      showModal: false,
      showConfirmPassword: false,
      showPassword: false,
    };
  },
  methods: {
    async register() {
      const { isValid, formError } = getFormValidation(this.form);
      this.isValid = isValid;
      this.formError = formError;
      if (!this.isValid) {
        return;
      }

      const userData = {
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        username: this.form.username,
        email: this.form.email,
        company_name: this.form.company,
        password: this.form.password,
      };

      const res = await this.$store.dispatch('register', userData);
      if (res.status === 200) {
        Feedback.showSuccess('Account successfully created.');
        this.$router.push({ name: 'Login' });
      } else
        Feedback.showError(
          'There was an error processing your data. Please try again.'
        );
    },
  },
};
</script>

<style lang="scss">
.row.g-3 {
  justify-content: center;
}

.el-dialog.privacy-modal {
  .el-dialog__title {
    @include title($accent-font, 24px, $bold);
  }
}
</style>
