import { ElNotification } from 'element-plus';

export default class Feedback {
  static showSuccess(text) {
    ElNotification({
      title: 'Success',
      message: text,
      type: 'success',
      offset: 120,
      duration: 5000,
    });
  }

  static showError(text) {
    ElNotification({
      title: 'Error',
      message: text,
      type: 'error',
      offset: 120,
      duration: 5000,
    });
  }
}
