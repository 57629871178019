function getEmptyFields(form) {
  let formError = {};
  Object.keys(form).forEach((key) => {
    if (form[key] === '') {
      switch (key) {
        case 'firstName':
          formError.firstName = 'First name is required';
          break;
        case 'lastName':
          formError.lastName = 'Last name is required';
          break;
        case 'username':
          formError.username = 'Username is required';
          break;
        case 'company':
          formError.company = 'Company is required';
          break;
        case 'email':
          formError.email = 'Email is required';
          break;
        case 'password':
          formError.password = 'Password is required';
          break;
        case 'passwordConfirm':
          formError.passwordConfirm = 'Password confirmation is required';
          break;
        case 'currentPassword':
          formError.currentPassword = 'Current password is required';
          break;
        case 'newPassword':
          formError.newPassword = 'New password is required';
          break;
        default: return null;
      }
    }
  });
  return formError;
}

function getPasswordValidation(password) {
  let passwordError = '';
  if (password.length < 10)
    passwordError = 'Password must be at least 10 characters';

  return passwordError;
}

function getPasswordConfirmation(password, passwordConfirm) {
  let passwordError = '';
  if (password !== passwordConfirm) passwordError = 'Passwords do not match';
  return passwordError;
}

function getUsernameValidation(username) {
  let usernameError = '';
  if (username.length < 8)
    usernameError = 'Username must be at least 8 characters';
  if (!username.match(/^[a-zA-Z0-9]+$/))
    usernameError = 'Username can only contain letters and numbers';
  return usernameError;
}

function getEmailValidation(email) {
  let emailError = '';
  if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
    emailError = 'Email is invalid';
  return emailError;
}

function getFormValidation(form) {
  let isValid = false;
  let formError = getEmptyFields(form);

  formError = {
    ...formError,
    username: getUsernameValidation(form.username),
    email: getEmailValidation(form.email),
    password: getPasswordValidation(form.password),
    passwordConfirm: getPasswordConfirmation(
      form.password,
      form.passwordConfirm
    ),
  };

  Object.keys(form).forEach((key) => {
    if (formError[key] === '') isValid = true;
    else isValid = false;
  });

  return { isValid, formError };
}

function getProfileValidation(form) {
  let isValid = true;
  let formError = getEmptyFields(form);

  formError = {
    ...formError,
    username: getUsernameValidation(form.username),
    email: getEmailValidation(form.email),
  };

  Object.keys(form).forEach((key) => {
    if (formError[key] === '' || formError[key] === undefined) isValid = false;
  });

  return { isValid, formError };
}

export {
  getEmptyFields,
  getPasswordValidation,
  getPasswordConfirmation,
  getUsernameValidation,
  getEmailValidation,
  getFormValidation,
  getProfileValidation,
};
